<template>
  <div class="chat">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-0 view-block">
        <div class="row margin-0">
          <div class="col pad-0 inb-side-block">
            <div class="inbox-head" v-if="false">
              <div class="d-flex">
                <!-- <span v-if="selectedChat" style="font-size:1.4em; margin-bottom: 0px; margin-right: 5px" class="fa fa-comment margin-right-20"></span> -->
                <h3>CHAT</h3>
              </div>
              <input type="search" placeholder="Search ad, name and more..." name="" id="">
            </div>
            <div class="inbox-list">
              <ul class="i-list">
                <template>
                  <li :class="{'active': selectedChat && item.id===selectedChat.id}" :key="index" v-for="(item,index) in myChatList" @click="updateSelectedChart(item)">
                    <div class="inb-head">
                      <div class="img-block">
                        <img :src="item.thumbnail" class="img-fluid" alt="" srcset="">
                      </div>
                      <div class="title-block">
                        <h5><span class="ad-title">{{item.title}}</span> <span class="time-labeling">{{getDate(item.timestamp)}}</span></h5>
                        <p class="margin-top-5" :class="{'active-seen':item.count>0}">
                          <template v-if="chatHistory1[item.id] && chatHistory1[item.id].messages && chatHistory1[item.id].messages.length>0 && chatHistory1[item.id].messages[0]!==null">
                            <span class="n-message">
                              <template v-if="chatHistory1[item.id].messages[0].owner===profile.id">
                                <ion-icon v-if="chatHistory1[item.id].messages[0].status==='sent'" name="checkmark-outline"></ion-icon>
                                <ion-icon v-if="chatHistory1[item.id].messages[0].status==='delivered'" name="checkmark-done-outline"></ion-icon>
                                <ion-icon v-if="chatHistory1[item.id].messages[0].status==='seen'" class="seen" name="checkmark-done-outline"></ion-icon>
                              </template>
                              <span v-if="chatHistory1[item.id].messages[0]" class="ad-message"> {{chatHistory1[item.id].messages[0].message}}</span>
                            </span>
                            <span v-if="item.count>0" class="unread-count">{{item.count}}</span>
                          </template>
                        </p>
                      </div>
                    </div>
                  </li>
                </template>
                <!-- <template v-else-if="loading_inbox">
                  <li :key="item" v-for="item in [1,2,3,4,5,6,78]">
                    <div class="pad-10">
                      <h5 class="shine pad-5 margin-bottom-10" style="max-width:50%"></h5>
                      <h5 class="shine pad-5 margin-bottom-10" style="max-width:70%"></h5>
                      <p class="shine pad-5 margin-0"></p>
                    </div>
                  </li>
                </template>
                <template v-else>
                  <li class="text-center">
                    <span>No chats found</span>
                  </li>
                </template> -->
              </ul>
            </div>
          </div>
          <div class="col pad-0" v-if="mode==='web'||selectedChat" :class="{'inb-detail-block':mode==='mobile'}">
            <template v-if="selectedChat">
              <div class="inb-d-head">
                <div class="tit">
                  <h4 style="margin-bottom: 0px"><span v-if="mode==='mobile'" @click="clearSelection()" class="fa fa-chevron-left pad-right-15"></span>{{selectedChat.title}}</h4>
                </div>
                <div class="cont">
                  <h3 v-if="selectedChat" style="font-size:1.4em; margin-bottom: 0px" class="fa fa-phone margin-right-20"></h3>
                  <!-- <h3 v-if="selectedChat" style="font-size:1.4em; margin-bottom: 0px" class="fa fa-whatsapp margin-right-20"></h3> -->
                  <h3 v-if="selectedChat" style="font-size:1.4em; margin-bottom: 0px" class="fa fa-ellipsis-v margin-right-20"></h3>
                </div>
              </div>
              <div class="inb-d-body">
                <div v-if="selectedChat" class="ad-link" @click="$router.push(`/view/${selectedChat.product.id}/`)">
                  <h5>{{selectedChat.product.title}}</h5>
                  <span style="font-weight: bold" class="text-success"><span class="fa fa-inr"></span> {{parseFloat(selectedChat.product.price).toLocaleString()}}</span>
                </div>
                <ul class="m-list">
                  <template v-if="conversations.length>0">
                    <li :key="index" v-for="(item,index) in conversations">
                      <div class="message" :class="{'own':item.owner && item.owner === profile.id}">
                        <p>{{item.message}}</p>
                        <!-- <span class="f-right"><time-label :date="new Date(item.timestamp)" :label="false"></time-label></span> -->
                        <span class="time">{{new Date(item.timestamp).toLocaleTimeString('en-US', { hour12: true })}}</span>
                      </div>
                    </li>
                  </template>
                  <!-- <template v-else-if="loading_messages">
                    <li :key="item" v-for="item in [1,2,3,4,5,6,78]">
                      <h5 class="shine pad-5 margin-bottom-10" style="max-width:50%"></h5>
                      <p class="shine margin-0"></p>
                      <span class="shine"></span>
                    </li>
                  </template>
                  <template v-else>
                    <li>
                      No message found
                    </li>
                  </template> -->
                </ul>
                <div class="inpu-msg">
                  <input type="text" v-model="msg" placeholder="Type your message....." @keyup.enter="addMessage(msg)" class="form-control">
                  <button class="btn btn-success" :disabled="!msg" @click="addMessage(msg)"><span style="font-size:1.4em; margin-bottom: 0px" class="fa fa-arrow-right"></span></button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="no-chat">
                <div class="text-center">
                  <img src="https://image.flaticon.com/icons/svg/2950/2950581.svg" alt="">
                  <p>Select any chat from the list.</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import TimeLabel from '@/components/TimeLabel.vue'
export default {
  components: {
    TimeLabel
  },
  props: ["id", "chatid"],
  data() {
    return {
      msg: null,
      chatDetail: {},
      messages: [],
      messagesList: {},
      loading: true,
      visible_contact: false,
      loading_inbox: true,
      loading_messages: false,
      currentChartId: null,
      testRender: "yes",
      chat_order: [],
      mode: "web"
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.account.profile,
      chats: (state) => state.account.chats,
      conversations: (state) => state.account.conversations,
      selectedChat: (state) => state.account.selectedChat,
      chatHistory: (state) => state.account.chatHistory,
    }),
    myChatList() {
      const chats = [];
      this.chats.forEach(item => {
        chats.push(this.prepareChat(item));
        this.chat_order.push(item.id);
      });
      return chats;
    },
    chatHistory1() {
      return this.chatHistory;
    }
  },
  created() {
    if(this.id) {
      this.createChat();
    }
    if(screen.width < 768) {
      this.mode = "mobile";
    }
  },
  methods: {
    prepareChat(item) {
      const title = item.seller.id === this.profile.id?item.buyer.name:item.seller.name;
      const receiver = item.seller.id === this.profile.id?item.buyer.id:item.seller.id;
      const message = this.chatHistory[item.id] && this.chatHistory[item.id].messages.length>0?this.chatHistory[item.id].messages[0]:null;
      return {
        id: item.id,
        title, message,
        receiver,
        thumbnail: item.product.thumbnail || '/noimage.jpg',
        timestamp: new Date().getTime(),
        count: 0,
        product: item.product
      };
    },
    getDate(dt) {
      var dtt = new Date(dt);
      var days = 0;
      var intervals = {
        day: 1000 * 60 * 60 * 24
      }
      let diff = Math.abs(Date.now() - dtt.getTime());
      days = Math.floor(diff / intervals.day);
      if(days) {
        return `${dtt.getDate()} ${dtt.toLocaleString('default', { month: 'short' })}`;
      }
      else {
        return `${dtt.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}`;
      }
    },
    createChat(){
      this.loading = true;
      this.$cs.chat.create({
        resource: `${this.$cs.chat.api}new/`,
        data: {
          product: this.id
        }
      })
      .then(res => {
        if(this.chat_order.indexOf(res.data.id)!==-1) {
          this.$store.dispatch("selectedChat", this.myChatList[this.chat_order.indexOf(res.data.id)]);
        } else {
          const chat = this.prepareChat(res.data);
          this.$store.dispatch("addChat", res.data);
          this.$store.dispatch("getChat", chat);
          this.$store.dispatch("selectedChat", chat);
        }
      })
    },
    getMyChat(){
      this.loading = true;
      this.$cs.chat.list({
        resource: `${this.$cs.chat.api}${this.chatid}/`
      })
      .then(res => {
        this.selectedChat = res.data;
        this.loading = false;
      })
    },
    updateSelectedChart(item) {
      this.$store.dispatch("selectedChat", item);
    },
    clearSelection() {
      this.$store.dispatch("selectedChat", null);
    },
    addMessage() {
      const messageId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));
      let message = {
        message: this.msg,
        status: 'sent',
        owner: this.profile.id,
        timestamp: new Date().getTime(),
        _id: messageId(),
      }
      this.$socket.emit("message", {
        roomId: this.selectedChat?this.selectedChat.id:null,
        receiver: this.selectedChat.receiver,
        sender: this.profile.id,
        type: 'DM',
        data: message
      });
      this.msg = "";
    }
  }
};
</script>
<style lang="stylus" scoped>
.inb-detail-block
  position fixed
  background-color #fff
  z-index 1000
.chat {
  .view-block {
    background-color: #ffffff;
    // border: 1px solid #aaa;
    box-shadow 0px 0px 20px #ccc;
    overflow hidden;
  }
}
.inb-side-block
  background-color #f6f6f6
  // border-right 1px solid #aaa
  min-height 75vh
  max-width 28%
  .inbox-head
    padding 18px 10px
    background-color #00699c
    color #fff
    h4
      margin 0px 0px 10px
      font-weight 600
      font-size 0.8em
    input
      width 100%
      border none
      border-radius 30px
      font-size 0.75em
      padding 8px 10px
      outline none
  .inbox-list
    .i-list
      margin 0px
      padding 0px
      list-style none
      min-height calc(100vh - 26vh)
      max-height calc(100vh - 26vh)
      overflow-y auto
      li
        padding 10px 0px
        cursor pointer
        // min-height 80px
        background-color #f6f6f6
        display block
        overflow hidden
        &.active
          background-color #ffffff
        .inb-head
          padding 0px 0px 0px 10px
          display flex
          .img-block
            min-width 50px
            max-width 50px
            padding 0px
            display: block;
            width: 50px;
            height: 50px;
            background: #ffffff;
            text-align: center;
            align-content: center;
            align-items: center;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            overflow hidden
            img
              // border-radius 5px
              max-height: 100%;
              max-width: 100%;
          .title-block
            width calc(100% - 50px)
            padding 0px 10px 0px 10px
            h5
              margin 5px 0px 0px
              font-weight 600
              color: #00699c;
              font-size: 0.8em;
              text-overflow ellipsis
              white-space nowrap
              overflow hidden
              .ad-title
                max-width: calc(100% - 60px);
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              .time-labeling
                color: #666666;
                font-size: 0.8em;
                font-weight: background-color;
                letter-spacing: 0.3px;
                float: right;
            p
              // margin: 7px 0px 0px;
              margin 0px
              color: #444;
              font-size: 1em;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 100%;
              .n-message
                font-size 0.7em
                font-weight 400
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
              ion-icon
                position: relative;
                top: 3px;
                color: #999999;
                &.seen
                  color: #3498DB!important;
              &.active-seen
                .n-message
                  max-width: calc(100% - 40px);
                  display: inline-block;
              .unread-count
                float:right;
                background-color: #06d756;
                color: #ffffff;
                border-radius: 50%;
                height: 1.6em;
                width: 1.6em;
                font-size: 0.9em;
                display: flex;
                justify-content: center;
                align-items: center;
            .product-tit
              font-size: 0.75em;
              display block
.inb-d-head
  padding 16px 12px
  display flex
  justify-content space-between
  align-items center
  h5
    margin 0px
    font-weight 800
  .cont 
    padding 5px 0px 5px 20px
    display flex
    align-items center
    justify-content center
    // border 1px solid red
    border-radius 30px
    box-shadow 0px 0px 3px #ccc
    color #fff
    background-color #00699c
.inb-d-body
  background-color #f6f6f6
  .ad-link
    padding 10px 12px
    // background-color #fff
    cursor pointer
    display flex
    align-items center
    justify-content space-between
    h5
      font-size 1em
      margin 0px
      font-weight 500
      color #00699c
  .m-list
    min-height calc(100vh - 33vh)
    max-height calc(100vh - 36vh)
    // height 70vh
    list-style none
    margin 0px
    margin-bottom 50px
    padding 0px 12px
    display: flex;
    flex-direction: column-reverse;
    overflow-y auto
    background-color #fff
    li
      padding 10px
      .message
        display inline-block
        font-size 0.5em
        p
          padding 5px 10px
          margin 0px 0px 5px
          font-size 1.7em
          background-color #fff
          border none
          border-radius 0px 30px 30px 30px
          box-shadow: 0px 0px 1px #000;
          // max-width 80%
        .time
          font-size 1.2em
          letter-spacing 0.4px
          color #666
        &.own
          float right
          p
            border-radius 30px 0px 30px 30px
            padding 5px 10px
            background-color #D6EAF8
          .time
            float right
.inpu-msg
  display flex
  justify-content space-between
  position absolute
  bottom 0
  left 0
  right 0
  padding 9px
  padding-left 12px
  background-color #fff
  border-top 2px solid #eee
  
  input
    width 88%
    border-radius 30px
    border 1px solid #eee
    @media (max-width: 767px)
      width 80%
  button
    width 10%
    border-radius 30px
    margin-right 6px
    @media (max-width: 767px)
      width 20%
.no-chat
  padding 15px
  min-height 70vh
  display flex
  justify-content center
  align-items center
  img
    max-width 20vw
  p
    color #666666
@media (max-width: 767px)
  .chat
    padding 0px
    .view-block
      min-height 100vh
  .inb-side-block
    max-width 100%
    min-width 100%
    min-height 100vh
    max-height 100vh
    .inbox-list
      .i-list
        min-height 100vh
        max-height 100vh
        li.active
          background-color transparent
  .inb-d-head
    padding 20px 5px
</style>
